import React, { FC } from 'react'
import Script from 'next/script';

interface GlobalHeaderProps {
  environment: string
}

const GlobalFooter: FC<GlobalHeaderProps> = ({ environment = 'Prod' }) => {

  return (
    <div className="global-footer">
		{/* Not needed as we are already referencing from global header */}
	  {/* <link rel="modulepreload" href={process.env.HOST + `/webcomponent/dist/brighttalk-web-components/brighttalk-web-components.esm.js`}></link>
      <Script
        src={
          process.env.HOST +
          `/webcomponent/dist/brighttalk-web-components/brighttalk-web-components.esm.js`
        }
        type="module"
      /> */}
	   <style jsx>{`
        .global-footer {
			background-color: rgb(92, 92, 92);
			min-height: 394px;
		}
      `}</style>
      {/* @ts-ignore */}
      <portal-footer env={environment} />
    </div>
  );
}

export default GlobalFooter
