import React, { FC, useEffect, useState } from 'react'
import Script from 'next/script';


interface GlobalHeaderProps {
  environment: string
  searchBar: boolean
  joinBtn: boolean
  isLoggedIn?: boolean
  isChannelOwner?: boolean
  isManager?: boolean
  loginRedirect?: string
  isPreAuthenticated?: boolean
  hostUrl?: string
}

const GlobalHeader: FC<GlobalHeaderProps> = ({ environment, searchBar = true, joinBtn = true, isLoggedIn, isChannelOwner, isManager, loginRedirect, isPreAuthenticated, hostUrl }) => {

  const [pageLoaded, setPageLoaded] = useState(false)

  useEffect(() => {
    setPageLoaded(true)
  }, [])

  let baseUrl = hostUrl;
  if ( !hostUrl ){
	baseUrl = process.env.HOST
  }

  return (
    <div className="global-header">
	  <link rel="modulepreload" href={baseUrl + `/webcomponent/dist/brighttalk-web-components/brighttalk-web-components.esm.js`}></link>
      <Script
        src={baseUrl + `/webcomponent/dist/brighttalk-web-components/brighttalk-web-components.esm.js`}
        type="module"
      />
      <style>{`
       .global-header {
        background-color: white;
        min-height: 106px;
        margin-bottom: 30px;
       }
      `}</style>
      {pageLoaded && <portal-header env={environment} search={searchBar} join={joinBtn} loggedin={isLoggedIn} manager={isManager} channelowner={isChannelOwner} loginRedirect={loginRedirect} preauth={isPreAuthenticated} />}
    </div>
  );
}

export default GlobalHeader
